<template>
  <el-card class="grid-results-wrapper page-container" v-loading="loading" element-loading-text="文件下载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="grid-container" :class="{ 'no-data': !source.length }">
      <div class="grid-wrap" v-if="source.length">
        <div class="grid-item" v-for="(item, index) in source" :key="item.fileName">
          <div class="grid-item-wrap">
            <!-- <el-image-pro :src="item.fileShowThumbnail" :preview-src-list="imgs" :i="index" @getCurrentDownloadFile="getCurrentDownloadFile" :uri="item.filePreview"></el-image-pro> -->
            <el-image-pro :src="item.fileShowThumbnail" :i="index" @getCurrentDownloadFile="getCurrentDownloadFile" @preview:getPreviewImage="handlePreviewImage" :uri="item.filePreview"></el-image-pro>
            <div class="protective">
              <p class="time size">时间：{{ item.fileLastModified }}</p>
              <p class="file-size size">大小：{{ formatFileSize(item.fileSize) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else>暂无数据</div>
    </div>
    <el-dialog :close-on-click-modal="false" title="文件预览" :visible="dialogVisiblePreview" @close="handleClose">
      <video :src="mediaSource" v-if="suffix === 'mp4'" width="100%" controls></video>
      <img :src="mediaSource" alt="" v-else style="width: 100%" />
    </el-dialog>
  </el-card>
</template>

<script>
import ElImagePro from '@/components/image/index.vue';
import { getMediaFileList, getDownloadInAdvanceFile } from '@/api/media/index.js';
import getHashCode from '@/utils/hash.js';
import statusInclude from '@/utils/statusCode';
export default {
  name: 'GridResult',
  components: {
    ElImagePro,
  },
  data() {
    return {
      ins: '',
      downloadFileName: '',
      drawer: null,
      gross: 0,
      sourceCount: 0,
      progess: 0,
      downloading: false,
      dialogVisiblePreview: false,
      isAll: false,
      source: [],
      imgs: [],
      mediaSource: null,
      fileName: '',
    };
  },
  computed: {
    siteID() {
      return this.$route.query.s; //'ST_00000017';  //
    },
    missionBatch() {
      return this.$route.query.m; //'A071656749066778220798'; //
    },
    loading() {
      return this.downloading;
    },

    suffix() {
      return this.fileName?.substring(this.fileName?.lastIndexOf('.') + 1) || false;
    },
  },
  created() {
    this.getMediaFileList();
  },
  methods: {
    /**
     * 格式化文件大小
     * @param {*} value
     */
    formatFileSize(size) {
      let value = Number(size);
      if (size && !isNaN(value)) {
        const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB', 'BB'];
        let index = 0;
        let k = value;
        if (value >= 1024) {
          while (k > 1024) {
            k = k / 1024;
            index++;
          }
        }
        return `${k.toFixed(2)}${units[index]}`;
      }
      return '-';
    },

    getCurrentDownloadFile(index) {
      this.downloadCurrentFile(this.source[index]);
    },

    handlePreviewImage(index) {
      const { fileName, filePreview } = this.source[index];
      this.fileName = fileName;
      this.mediaSource = filePreview;
      this.dialogVisiblePreview = true;
    },

    handleClose() {
      this.dialogVisiblePreview = false;
    },

    downloadCurrentFile({ fileFullUrl, fileSize, fileType }) {
      this.downloadInAdvance = fileFullUrl;
      this.getDownloadInAdvanceFile();
    },

    async getMediaFileList() {
      try {
        const response = await getMediaFileList(this.siteID, '', `${this.siteID}/${this.missionBatch}/`);
        if (statusInclude(response.code)) {
          this.source = response.data;
          this.imgs = this.source.map(({ filePreview }) => filePreview);
        } else this.$message.error(response.reason);
      } catch (err) {
        this.$message.error(err);
        throw new Error(err);
      }
    },

    //下载媒体文件 zip
    async getDownloadInAdvanceFile() {
      this.progess = 0;
      if (!this.downloadInAdvance) return this.$message.warning('请选中需要下载的文件');
      this.downloading = true;
      const firstName = getHashCode();
      const suffix = 'zip';
      const fileName = `${firstName}.${suffix}`;
      this.downloadFileName = fileName;
      this.drawer = true;
      const onUploadProgress = (ProgressEvent) => {
        this.sourceCount = ProgressEvent.loaded;
        let progressPercent = parseInt((ProgressEvent.loaded / this.gross) * 100);
        this.progess = progressPercent;
        this.progess === 100 && console.log('end'); //理想状态下的完全下载成功
      };
      const blob = await getDownloadInAdvanceFile(this.downloadInAdvance, onUploadProgress);
      this.downloading = false;
      //处理进度条
      this.sourceCount = this.gross; //下载完成后不论结果如何直接将预测值覆盖实际下载结果
      this.progess = 100; //下载进度设置为100
      this.$message({ type: 'success', message: '文件下载成功' });

      const href = URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
      const a = document.createElement('a'); //创建a标签
      a.style.display = 'none';
      a.href = href; // 指定下载链接
      a.download = fileName; //指定下载文件名
      a.click(); //触发下载
      URL.revokeObjectURL(a.href); //释放URL对象
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-results-wrapper {
  .grid-container {
    // height: calc(100vh - 230px);
    height: 100%;
    overflow-y: auto;
    .grid-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      .grid-item {
        display: flex;
        font-display: row;
        justify-content: center;

        .grid-item-wrap {
          background-color: var(--main-side-bar-background);
          border-radius: 4px;
          overflow: hidden;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        }
        .protective {
          padding: 10px;
          p {
            padding: 0;
            margin: 0;
            &.time {
              font-weight: 900;
            }
          }
          .size {
            padding: 4px 6px;
            color: var(--color-primary);
          }
        }
        &:hover .el-image-pro {
          // border: 1px solid var(--color-primary);
          box-sizing: border-box;
          cursor: pointer;
        }
      }
    }
    &.no-data {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: var(--el-color-primary);
    }
  }
}
</style>
