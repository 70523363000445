<template>
  <div class="el-image-pro" :style="width + ';' + height">
    <!-- :referrerPolicy="referrerPolicy" -->
    <el-image style="width: 100%; height: 100%" :src="src" :fit="fit" :alt="alt" :zIndex="zIndex" @click="handlePreviewImage(i)"> </el-image>
    <!-- <image-viewer ref="ImageViewer" :preview-src-list="previewSrcList" :i="i" @download="getCurrentSourceIndex"></image-viewer> -->
  </div>
</template>

<script>
import ImageViewer from './imageView.vue';
export default {
  name: 'ImagePro',
  components: {
    ImageViewer,
  },
  data() {
    return {
      flag: false,
      wrapperElem: null,
      hidElClassNameLists: ['el-image-viewer__mask', 'el-image-viewer__btn el-image-viewer__close', 'el-icon-close'],
    };
  },
  computed: {},
  props: {
    src: {
      type: String,
      default: '',
    },
    previewSrcList: {
      type: Array,
      default: () => [],
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
    fit: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    referrerPolicy: {
      type: String,
      default: '',
    },
    zIndex: {
      type: Number,
      default: 2000,
    },
    i: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    handlePreviewImage(index) {
      this.$emit('preview:getPreviewImage', index);
      // this.$refs.ImageViewer.showPreview = true;
    },
    getCurrentSourceIndex(index) {
      this.$refs.ImageViewer.showPreview = false;
      this.$emit('getCurrentDownloadFile', index);
    },
    downloadIMG() {
      let imgUrl = document.getElementsByClassName('el-image-viewer__canvas')[0].children[0].src;
      this.downloadImage(imgUrl);
    },
    downloadImage(imgUrl) {},
  },
};
</script>

<style lang="scss" scoped>
.el-image-pro {
  display: inline-block;
  .el-image-viewer__download {
    top: 40px;
    right: 100px;
    width: 40px;
    height: 40px;
    font-size: 24px;
    color: #fff;
    background-color: #606266;
    z-index: 2001; /* 如果该组件需要传递 z-index 的值，这个值也需要做成动态的 props */
    cursor: pointer;
    position: fixed;
  }
}
</style>
