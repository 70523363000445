<template>
  <div class="el-image-viewer-pro">
    <image-viewer v-if="showPreview" :urlList="previewSrcList" :initialIndex="i" :on-close="handleCloseView"> </image-viewer>
    <!-- <video :src="previewSrcList[i]" v-else-if="showPreview && fileType"></video>- -->
  </div>
</template>
<script>
import ImageViewer from 'element-ui/packages/image/src/image-viewer';
export default {
  props: {
    previewSrcList: {
      type: Array,
      default: [],
    },
    i: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      sign: 0,
      tagName: 'el-image-viewer__wrapper',
      showPreview: false,
      dom: Element,
    };
  },
  components: {
    ImageViewer,
  },
  computed: {
    practicable() {
      return this.previewSrcList.length - 1 > this.sign;
    },
    /**
     * @Author: 篆愁君
     * @Date: 2022-10-12 18:05:06
     * @description:
     * @return {*}
     * BUG index 错误
     */
    fileType() {
      return this.previewSrcLis[this.i].indexOf('.mp4') > -1;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.sign = this.i;
    });
  },
  methods: {
    handleCloseView() {
      this.showPreview = false;
    },

    handleClickButtonGroup() {
      const _self = this;
      const prev = document.getElementsByClassName('el-image-viewer__prev')[0];
      const next = document.getElementsByClassName('el-image-viewer__next')[0];
      prev.addEventListener('click', () => {
        if (_self.sign > 0) _self.sign -= 1;
        else _self.sign = _self.previewSrcList.length - 1;
      });
      next.addEventListener('click', () => {
        if (_self.practicable) _self.sign += 1;
        else _self.sign = 0;
      });
    },
  },
  watch: {
    showPreview(val) {
      if (val) {
        this.$nextTick(() => {
          this.handleClickButtonGroup();
          // console.log(this.fileType);
          // if (this.fileType) return;
          this.dom = document.getElementsByClassName(this.tagName);
          const downloadButton = document.createElement('span');
          const icon = document.createElement('i');
          icon.setAttribute('class', 'el-icon-download');
          downloadButton.setAttribute('class', 'el-image-viewer__btn el-image-viewer__download');
          downloadButton.appendChild(icon);
          downloadButton.addEventListener('click', () => {
            this.$emit('download', this.sign);
          });
          this.dom[0].appendChild(downloadButton);

          // const tag = document.getElementsByClassName(this.tagName)[0];
          // console.log(tag);
          // document.body.setAttribute('style', 'overflow: hidden'); //去除滚动跳，禁止滚动

          // this.dom[0].addEventListener('click', this.closeViewer, false); //监听遮罩点击事件，点击遮罩关闭预览
        });
      } else {
        document.body.removeAttribute('style');
        if (this.dom) {
          this.dom[0].removeEventListener('click', this.closeViewer);
        }
      }
    },
  },
};
</script>

<style>
.el-image-viewer__download {
  top: 40px;
  right: 100px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  color: #fff;
  background-color: #606266;
  z-index: 2001; /* 如果该组件需要传递 z-index 的值，这个值也需要做成动态的 props */
  cursor: pointer;
  position: fixed;
}
</style>
